@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  @include flex-column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--color-background-primary);
  z-index: 1004;
  overflow: hidden;
}

.inputContainer {
  @include flex-row;
  align-items: center;
  gap: var(--space-200);
  padding: var(--space-200);
}

.cancel {
  @include text-100;
}

.results {
  overflow: hidden;
}
