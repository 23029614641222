@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  @include flex-column;
}

.tabBar {
  margin: 0 var(--space-300) var(--space-300) var(--space-300);
}

.scrollable {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: var(--space-200);
}

.nullState,
.loadingState {
  padding: var(--space-150) var(--space-300);
}

.nullHeader,
.nullSubtext {
  word-wrap: break-word;
  text-align: center;
}

.nullHeader {
  @include text-200-bold;
  margin-bottom: var(--space-50);
}

.nullSubtext {
  @include text-100;
}
