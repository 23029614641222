@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  position: relative;
}

.input {
  position: relative;
  &.isActive {
    z-index: 4;
  }
  z-index: 1;
}

.dropdown {
  @include flex-column;
  position: absolute;
  z-index: 3;
  width: 100%;
  max-height: 480px;
  overflow: hidden;

  border-bottom-left-radius: var(--radius-md);
  border-bottom-right-radius: var(--radius-md);
  box-shadow: $shadow-md;
  background: var(--color-background-primary);

  &.inline {
    max-height: 420px;
    position: relative;
  }
}

.sm {
  .dropdown {
    top: 16px; // Half of the 32px tall SearchInput
    padding-top: calc(16px + var(--space-200));

    &.inline {
      top: -16px;
    }

    &.enveloping {
      width: calc(100% + 2 * var(--space-50));
      left: calc(-1 * var(--space-50));
      top: calc(-1 * var(--space-50));
      padding-top: 0;
      border-radius: 16px;

      &::before {
        flex-shrink: 0;
        content: '';
        display: block;
        width: 100%;
        height: calc(32px + 2 * var(--space-50)); // 32px SearchInput + padding around the search box
        margin-bottom: var(--space-50);
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        background: inherit;
      }
    }
  }
}

.md {
  .dropdown {
    top: 24px; // Half of the 48px tall SearchInput
    padding-top: calc(24px + var(--space-200));

    &.inline {
      top: -24px;
    }

    &.enveloping {
      width: calc(100% + 2 * var(--space-100));
      left: calc(-1 * var(--space-100));
      top: calc(-1 * var(--space-100));
      padding-top: 0;
      border-radius: 24px;

      &::before {
        flex-shrink: 0;
        content: '';
        display: block;
        width: 100%;
        height: calc(48px + 2 * var(--space-100)); // 48px SearchInput + padding around the search box
        margin-bottom: var(--space-100);
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        background: inherit;
      }
    }
  }
}

.homepage {
  // snowflake
  .input {
    height: 72px;
    padding-left: var(--space-300);
    padding-right: var(--space-300);

    &.input.input {
      box-shadow: $shadow-md; // Extra selectors needed to override the focus and hover box-shadows
    }

    input {
      @include text-300;
      margin-left: var(--space-200);
    }
  }

  .dropdown {
    top: 36px; // Half of the 72px tall SearchInput
    padding-top: calc(36px + var(--space-200));
  }
}
