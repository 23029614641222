@import "@alltrails/shared/styles/landsEnd.scss";
.container {
  background-color: var(--color-background-primary);
  padding: var(--space-150) var(--space-300);

  @include hover {
    background-color: var(--color-background-primary-hover);
    cursor: pointer;
  }

  &.active {
    background-color: var(--color-background-primary-hover);
  }
}
